/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DateTime } from 'luxon';
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  RegisterOptions,
  UseFormGetValues,
} from 'react-hook-form';
import { Role } from 'types/types';
import { mergeDateTimeAndZone } from 'utils/dates';
import { EventDetailsData, EventScheduleShare } from './types';

interface ErrorMap {
  [key: string]: string;
}

export type EventFieldError = Merge<
  FieldError,
  FieldErrorsImpl<{
    id: string;
    name: string;
    supportedRoles: NonNullable<Role>[];
  }>
>;

export const errorMap: ErrorMap = {
  // Generic
  required: 'ui.event-management.events.create.error.value-required',

  // CoreDetailsForm.tsx
  organisation: 'ui.event-management.events.create.error.organisation-required',
  name: 'ui.event-management.events.create.error.name-required',
  eventType: 'ui.event-management.events.create.error.experience-type-required',

  // EventDetailsForm.tsx
  dialect:
    'ui.event-management.events.create.error.experience-language-required',
  timezone: 'ui.event-management.events.create.error.timezone-required',
  startDate: 'ui.event-management.events.create.error.start-date-required',
  startTime: 'ui.event-management.events.create.error.start-time-required',
  endDate: 'ui.event-management.events.create.error.end-date-required',
  endTime: 'ui.event-management.events.create.error.end-time-required',
  deadlineAt:
    'ui.event-management.events.create.error.evaluator-deadline-required',
  isTodayOrFutureDate:
    'ui.event-management.events.create.error.today-or-future',
  isValidStartTime: 'ui.event-management.events.create.error.valid-start-time',
  isAfterStartDate: 'ui.event-management.events.create.error.after-start-date',
  isValidEndTime: 'ui.event-management.events.create.error.valid-end-time',
  isAfterStartTime: 'ui.event-management.events.create.error.after-start-time',
  isBeforeStartDate:
    'ui.event-management.events.create.error.before-start-date',
  isTomorrowOrFutureDate:
    'ui.event-management.events.create.error.tomorrow-or-future',
  isBeforeDeadlineDate:
    'ui.event-management.events.create.error.before-deadline-date',
  isValidStartDate: 'ui.event-management.events.create.error.valid-start-date',
  isValidEndDate: 'ui.event-management.events.create.error.valid-end-date',
  isValidDeadlineDate:
    'ui.event-management.events.create.error.valid-deadline-date',
  notificationFrequency:
    'ui.event-management.events.create.error.frequency-updates-required',

  // LocationForm.tsx
  addressLine1:
    'ui.event-management.events.create.error.address-line-1-required',
  city: 'ui.event-management.events.create.error.city-required',
  state: 'ui.event-management.events.create.error.state-required',
  postcode: 'ui.event-management.events.create.error.post-code-required',
  country: 'ui.event-management.events.create.error.country-required',

  // ManageUnitsForm.tsx & TransferUnitsForm.tsx
  comment: 'ui.event-management.transfer-units.comment.label.error',
  reference: 'ui.event-management.manage-units.reference.error',

  // SetReminderDialog.tsx
  reminderAt:
    'ui.event-management.events.set-reminder.error.valid-date-required',
  isValidReminderDate:
    'ui.event-management.events.set-reminder.error.valid-date',
};

export const getErrorMessage = (error: EventFieldError | undefined): string => {
  const errorName = error?.ref?.name;

  if (!errorName) {
    return '';
  }

  if (error.type === 'required') {
    return errorMap[errorName];
  }

  return error.type ? errorMap[error.type] : '';
};

const nowInZoneOf = (date: DateTime<boolean>) =>
  DateTime.local({ zone: date.zone });

export const isTodayOrFutureDate = (date: DateTime) => {
  if (!date.isValid) {
    return false;
  }

  const nowInDateTimezome = nowInZoneOf(date).startOf('day');
  return date.startOf('day') >= nowInDateTimezome;
};

export const isBeforeStartDate = (date: DateTime, startDate: DateTime) => {
  if (!date.isValid || !startDate.isValid) {
    return false;
  }

  return (
    date.startOf('day').diff(startDate.startOf('day'), ['hours']).hours < 0
  );
};

export const isValidStartDate = (date: DateTime) => date.isValid;

export const isValidStartTime = (date: DateTime) => date.isValid;

export const isValidEndDate = (date: DateTime) => date.isValid;

export const isValidEndTime = (date: DateTime) => date.isValid;

export const isValidDeadlineDate = (date: DateTime) => date.isValid;

export const isValidShareDate = (date: DateTime) => date.isValid;

export const isValidShareTime = (time: DateTime) => time.isValid;

export const isValidReminderDate = (date: DateTime) => date.isValid;

export const sameDayOrBefore = (
  start: DateTime<boolean>,
  end: DateTime<boolean>,
) => {
  return end.startOf('day').diff(start.startOf('day'), ['hours']).hours >= 0;
};

export const isValidPastDateRange = (
  start: null | DateTime<boolean>,
  end: null | DateTime<boolean>,
  rangeLimitDays: number,
) =>
  start?.isValid &&
  end?.isValid &&
  sameDayOrBefore(start, end) &&
  sameDayOrBefore(end, nowInZoneOf(end)) &&
  end.diff(start, 'days').days <= rangeLimitDays;

export const differentDateTimes = (
  startDate: DateTime,
  startTime: DateTime,
  endDate: DateTime,
  endTime: DateTime,
) => {
  if (
    !startDate.isValid ||
    !startTime.isValid ||
    !endDate.isValid ||
    !endTime.isValid
  ) {
    return false;
  }

  const start = DateTime.fromISO(
    `${startDate.toISO()!.substring(0, 10)}T${startTime
      .toISO()!
      .substring(11, 23)}Z`,
  );
  const end = DateTime.fromISO(
    `${endDate.toISO()!.substring(0, 10)}T${endTime
      .toISO()!
      .substring(11, 23)}Z`,
  );

  return end.diff(start, 'hours').hours > 0;
};

export const isTomorrowOrFutureDate = (date: DateTime) => {
  if (!date.isValid) {
    return false;
  }

  const selectedDate = DateTime.fromISO(
    `${date.startOf('day').toISO()!.substring(0, 10)}T${date
      .startOf('day')
      .toISO()!
      .substring(11, 23)}Z`,
  );
  const tomorrow = DateTime.fromISO(
    `${DateTime.utc()
      .plus({ days: 1 })
      .startOf('day')
      .toISO()
      .substring(0, 10)}T${DateTime.utc()
      .plus({ days: 1 })
      .startOf('day')
      .toISO()
      .substring(11, 23)}Z`,
  );
  return selectedDate >= tomorrow;
};

export const isBeforeDeadlineDate = (
  date: DateTime,
  deadlineDate: DateTime,
) => {
  if (!date.isValid || !deadlineDate.isValid) {
    return false;
  }

  return (
    date.startOf('day').diff(deadlineDate.startOf('day'), ['hours']).hours < 0
  );
};

export const startDateFieldRules: RegisterOptions = {
  required: true,
  validate: {
    isValidStartDate,
    isTodayOrFutureDate,
  },
};

export const startTimeFieldRules: RegisterOptions = {
  required: true,
  validate: {
    isValidStartTime,
  },
};

export const endDateFieldRules = (
  getValues: UseFormGetValues<EventDetailsData>,
): RegisterOptions => {
  return {
    required: true,
    validate: {
      isValidEndDate,
      isTodayOrFutureDate,
      isAfterStartDate: (endDate) => {
        const startDate = getValues('startDate');
        if (!startDate || !endDate) {
          return false;
        }

        return !isBeforeStartDate(endDate, startDate);
      },
    },
  };
};

export const endTimeFieldRules = (
  getValues: UseFormGetValues<EventDetailsData>,
): RegisterOptions => {
  return {
    required: true,
    validate: {
      isValidEndTime,
      isAfterStartTime: (endTime: DateTime) => {
        const [startDate, startTime, endDate] = getValues([
          'startDate',
          'startTime',
          'endDate',
        ]);
        if (!startDate || !startTime || !endDate || !endTime) {
          return false;
        }

        return differentDateTimes(startDate, startTime, endDate, endTime);
      },
    },
  };
};

export const deadlineFieldRules = (
  getValues: UseFormGetValues<EventDetailsData>,
): RegisterOptions => {
  return {
    required: true,
    validate: {
      isValidDeadlineDate,
      isTodayOrFutureDate,
      isBeforeStartDate: (deadlineDate: DateTime) => {
        const startDate = getValues('startDate');
        if (!deadlineDate || !startDate) {
          return false;
        }

        return isBeforeStartDate(deadlineDate, startDate);
      },
    },
  };
};

export const isAfterTodayOrDeadlineFieldRules = (
  deadlineAt: string,
): RegisterOptions => {
  return {
    required: true,
    validate: {
      isValidReminderDate,
      isTomorrowOrFutureDate,
      isBeforeDeadlineDate: (reminderAt: DateTime) => {
        if (!reminderAt.isValid || !deadlineAt) {
          return false;
        }

        const deadline = DateTime.fromISO(deadlineAt, { zone: 'default' });
        const reminder = DateTime.fromISO(
          `${reminderAt.startOf('day').toISO()!.substring(0, 10)}T${reminderAt
            .startOf('day')
            .toISO()!
            .substring(11, 23)}Z`,
        );

        return isBeforeDeadlineDate(reminder, deadline);
      },
    },
  };
};

export const scheduleShareDateFieldRules = (): RegisterOptions => {
  return {
    required: true,
    validate: {
      isValidShareDate,
      isTodayOrFutureDate,
    },
  };
};

export const scheduleShareTimeFieldRules = (
  getValues: UseFormGetValues<EventScheduleShare>,
  zone: string,
): RegisterOptions => {
  const isScheduledAfterNow = (time: DateTime) => {
    if (!time.isValid) {
      return false;
    }
    const date = getValues('shareProfilesAtDate');
    if (!date?.isValid) {
      return undefined;
    }
    const dateAndTimeInEventTimezone = mergeDateTimeAndZone(date, time, zone);

    const nowInEventTimezone = DateTime.local({ zone });
    return dateAndTimeInEventTimezone >= nowInEventTimezone;
  };
  return {
    required: true,
    validate: {
      isValidShareTime,
      isScheduledAfterNow,
    },
  };
};

/* eslint-enable @typescript-eslint/no-non-null-assertion */
